
























import { IOption } from "@/models/interface-v2/common.interface";
import { UomConversions } from "@/models/interface-v2/uom.interface";
import { ResponseDetailProduct } from "@interface/product.interface";
import { productService } from "@service/product.service";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectUomConv",
  props: {
    value: {
      type: String,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    propProductId: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      loading: false,
      listOpt: [] as IOption<UomConversions>[],
      productDetail: {} as ResponseDetailProduct
    };
  },
  watch: {
    propProductId: {
      immediate: true,
      deep: true,
      handler(newval) {
        if (newval) {
          this.findProduct(this.propProductId);
        }
      }
    }
  },
  methods: {
    onSelect(val: string, meta?: IOption<UomConversions>): void {
      this.$emit("input", val);
      this.$emit("on-select", { value: val, meta });
      this.$emit("on-select-with-detail", this.productDetail);
    },
    getDetailProduct(id: string): Promise<ResponseDetailProduct> {
      return productService.detailProduct(id);
    },
    async findProduct(productId: string): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getDetailProduct(productId);
        this.fillUom(res);
      } catch (error) {
        this.$message.error({
          content: this.$t("notif_process_fail").toString()
        });
      } finally {
        this.loading = false;
      }
    },
    fillUom(product: ResponseDetailProduct): void {
      if (product.uomConversions && product.uomConversions.length) {
        this.listOpt = product.uomConversions.map(x => {
          return { key: x.unitUom, value: x.unitUomId, meta: x };
        });
      }
      const meta = this.listOpt.find(x => x.value === product.baseUnitId);
      this.onSelect(product.baseUnitId, meta);
      this.$emit("on-select-with-detail", product);
      this.productDetail = product;
    }
  }
});

